/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { FormatLocaleDefinition } from 'd3-format';
import { RegistryWithDefaultKey, OverwritePolicy } from '../models';
import { DEFAULT_D3_FORMAT } from './D3FormatConfig';
import createD3NumberFormatter from './factories/createD3NumberFormatter';
import createSmartNumberFormatter from './factories/createSmartNumberFormatter';
import NumberFormats from './NumberFormats';
import NumberFormatter from './NumberFormatter';
import { format as d3Format, formatLocale } from 'd3-format';

//import {
//  createDurationFormatter,
//} from '@superset-ui/core';

function roundOffNumber(number)
{
  var rounded_number=String(number.toFixed(2))
  var ar_rounded_number = rounded_number.split(".")
  return ar_rounded_number[1]? ar_rounded_number[1]=="00"? ar_rounded_number[0]: rounded_number : rounded_number;
}

function roundOffDecimalNumber(number, _decimal_count)
{
  var rounded_number=String(number.toFixed(_decimal_count))
  var ar_rounded_number = rounded_number.split(".")
  return ar_rounded_number[1]? ar_rounded_number[1]=="0"? ar_rounded_number[0]: rounded_number : rounded_number;
}

function convertNumberToIndianCurrencyText(number) {
   if(number) {
      if(number >=100000 && number < 10000000)
      {
        return roundOffNumber(number/100000) + " Lakh";
      }
      else if(number >=10000000)
      {
        return roundOffNumber(number/10000000) + " Crore";
      }
      else{
         return roundOffNumber(number);
      }
   }
   return number;
}
function createD3IndianCurrencyTextFormatter(config) {
  const {
    description,
    formatString,
    label,
    locale
  } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    /*formatFunc = typeof locale === 'undefined' ? d3Format(formatString): formatLocale(locale).format(formatString); */
    formatFunc = value => `₹ ${convertNumberToIndianCurrencyText(value)}`;
  } catch (error) {
    formatFunc = value => `${value} (Invalid format: ${formatString})`;

    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label
  });
}
function createD3NumberWithoutSymbolFormatter(config) {
  const {
    description,
    formatString,
    label,
    locale
  } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    /*formatFunc = typeof locale === 'undefined' ? d3Format(formatString): formatLocale(locale).format(formatString); */
    formatFunc = value => `${convertNumberToIndianCurrencyText(value)}`;
  } catch (error) {
    formatFunc = value => `${value} (Invalid format: ${formatString})`;
    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label
  });
}

function convertNumberToDurationText(number) {
  var _decimal_count = 1
  if(number) {
    if(number < 60) {
      return roundOffDecimalNumber(number, _decimal_count) + " Min";
    } else if(number == 60) {
      return roundOffDecimalNumber((number/60), _decimal_count) + " Hr";
    } else if(number > 60 && number < 1440) {
      return roundOffDecimalNumber((number/60), _decimal_count) + " Hrs";
    } else if(number == 1440) {
      return roundOffDecimalNumber((number/1440), _decimal_count) + " Day";
    } else if(number > 1440) {
      return roundOffDecimalNumber((number/1440), _decimal_count) + " Days";
    } else {
        return roundOffDecimalNumber(number, _decimal_count);
    }
  }
  return number;
}

function createD3DurationINHoursDaysFormatter(config) {
  const {
    description,
    formatString,
    label,
    locale
  } = config;
  let formatFunc;
  let isInvalid = false;

  try {
    /*formatFunc = typeof locale === 'undefined' ? d3Format(formatString): formatLocale(locale).format(formatString); */
    formatFunc = value => `${convertNumberToDurationText(value)}`;
  } catch (error) {
    formatFunc = value => `${value} (Invalid format: ${formatString})`;

    isInvalid = true;
  }

  return new NumberFormatter({
    description,
    formatFunc,
    id: formatString,
    isInvalid,
    label
  });
}

export default class NumberFormatterRegistry extends RegistryWithDefaultKey<
  NumberFormatter,
  NumberFormatter
> {
  d3Format: FormatLocaleDefinition;

  constructor() {
    super({
      name: 'NumberFormatter',
      overwritePolicy: OverwritePolicy.Warn,
    });

    this.registerValue(
      NumberFormats.SMART_NUMBER,
      createSmartNumberFormatter(),
    );
    /* Self defined formats */
    this.registerValue(NumberFormats.INDIAN_CURRENCY_TEXT, createD3IndianCurrencyTextFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['₹ ', ''],
        },
        formatString: '$,.0f',
      }));
    this.registerValue(NumberFormats.INDIAN_CURRENCY_NUMBER, createD3NumberFormatter({
        locale: {
          decimal: '.',
          thousands: ',',
          grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
          currency: ['₹ ', ''],
        },
        formatString: '$,.0f',
      }));
    this.registerValue(NumberFormats.DURATION_IN_HOUR_DAYS, createD3DurationINHoursDaysFormatter({
          locale: {
            decimal: '.',
            thousands: ',',
            grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
            currency: ['t ', ''],
          },
          formatString: '$,.0f',
        }));
        this.registerValue(NumberFormats.NUMBER_WITHOUT_SYMBOL, createD3NumberWithoutSymbolFormatter({
          locale: {
            decimal: '.',
            thousands: ',',
            grouping: [3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
            currency: [' ', ''],
          },
          formatString: '$,.0f',
        }));

    /* ----------------------------------------------------------------  */

    this.registerValue(
      NumberFormats.SMART_NUMBER_SIGNED,
      createSmartNumberFormatter({ signed: true }),
    );
    this.setDefaultKey(NumberFormats.SMART_NUMBER);
    this.d3Format = DEFAULT_D3_FORMAT;
  }

  setD3Format(d3Format: Partial<FormatLocaleDefinition>) {
    this.d3Format = { ...DEFAULT_D3_FORMAT, ...d3Format };
    return this;
  }

  get(formatterId?: string) {
    const targetFormat = `${
      formatterId === null ||
      typeof formatterId === 'undefined' ||
      formatterId === ''
        ? this.defaultKey
        : formatterId
    }`.trim();

    if (this.has(targetFormat)) {
      return super.get(targetFormat) as NumberFormatter;
    }

    // Create new formatter if does not exist
    const formatter = createD3NumberFormatter({
      formatString: targetFormat,
      locale: this.d3Format,
    });
    this.registerValue(targetFormat, formatter);

    return formatter;
  }

  format(
    formatterId: string | undefined,
    value: number | null | undefined,
  ): string {
    return this.get(formatterId)(value);
  }
}
